$desktop-S: 75rem; // 1200
$laptop: 64rem; // 1024
$tablet-L: 62rem; // 992
$tablet: 48rem; // 768
$tablet-S: 36rem; // 576
$mobile-L: 29.9375rem; // 479

//.header {
//  padding: 2rem;
//  width: 100%;
//  text-align: center;
//  font-size: 0.875rem;
//  background-color: #94e3ce;
//}

// form calculator
.calc-wrp {
  //font-family: 'Poppins', sans-serif;
  //margin: 2.5rem;
  &__container {
    width: 90vw;
    max-width: 62.25rem;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: $laptop) {
      width: auto;
      max-width: 62.25rem;
    }
  }
  input {
    outline: none;
    color: #000;
    &[type='submit'] {
      cursor: pointer;
    }
  }
  &__btn-calc {
    color: #ffffff !important;
    background-color: #ffc842;
    border: none;
    margin-top: 1.875rem;
    cursor: pointer;
    outline: none;
    position: relative;
    height: 3.5rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    transition: all 300ms ease;
  }
  @media (max-width: $tablet-L) {
    &__inner-line {
      flex-wrap: wrap;
    }
    input {
      width: 100%;
    }
  }
  @media (max-width: $tablet) {
    margin: 0.5rem;
  }
}

.calc-wrp {
  .calculator {
    margin-bottom: 0;
    padding: 3.25rem;

    &__price-label {
      margin-bottom: 0.625rem;
      font-size: 0.6875rem;
      letter-spacing: -0.03125em;
    }

    &__fields {
      @media (max-width: $desktop-S) {
        margin-right: 1.75rem;
      }
    }

    @media (max-width: $desktop-S) {
      padding: 1.75rem;
    }
    @media (max-width: $tablet-L) {
      padding: 1.2rem;
    }

    &__prices {
      @media (max-width: $tablet-L) {
        width: 100%;
      }
    }

    &__graphics {
      width: 100%;
      max-width: 100%;
    }

    &__price-label,
    &__price-number {
      text-align: center;
    }
  }

  .form {
    margin: 0;
    height: 100%;

    &__item {
      margin-bottom: 1.875rem;

      &.hide {
        display: none;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all 0.2s;
      }
    }

    &__layout {
      grid-row-gap: 0;
      margin-bottom: 0;
    }

    &__wrapper {
      margin: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    &__item {
      &.condition-item {
        margin-bottom: 0;
      }

      @media (max-width: $tablet-L) {
        margin-bottom: 0.875rem;
      }
    }
  }

  .chart__price {
    position: relative;

    &::before {
      content: '$';
      margin-right: 0.3125em;
      left: 0;
    }
  }

  .checkbox-inline {
    position: relative;
    display: flex;
    margin-bottom: 0;
    padding-left: 1.625rem;
    align-items: center;
    margin-top: 0.375rem;

    input[type='checkbox'] {
      left: 0;
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:checked ~ .checkbox__icon {
        border: 1px solid #ffc842;
        background-color: #ffc842;
        background-image: url(https://uploads-ssl.webflow.com/6284bc411e7490c177b50355/6284bd274fd8832b710ab303_check.svg);
        background-size: auto;
      }
    }
  }

  .checkbox {
    &__label {
      display: inline-block;
      cursor: pointer;
      font-weight: normal;
    }

    &__icon {
      border-radius: 0.125rem;
      width: 1rem;
      height: 1rem;
      margin-top: 0;
      margin-right: 0.625rem;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      left: 0;
      opacity: 1;
      position: absolute;
      z-index: 1;
      border: 1px solid #bdbdbd;
      cursor: pointer;
    }
  }

  label {
    &.radio {
      input[type='radio'] {
        opacity: 0;
        position: absolute;
        z-index: -1;

        &:checked ~ .radio__icon {
          background-color: #333;
          color: #fff;
          transition: all 0.2s;
        }
      }
    }
  }

  .radio {
    &__icon {
      transition: all 0.2s;
    }
  }

  .calculator__graphics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chart {
    &__donut {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
    }

    &__donut-wrp {
      flex: 1;
    }

    #donutBox {
      position: relative;
      z-index: 3;
    }

    &__text {
      top: calc(50% - 2.5rem);
      z-index: 1;
      @media (max-width: $mobile-L) {
        width: 8.8125rem;
      }
    }

    &__price {
      @media (max-width: $mobile-L) {
        font-size: 1rem;
      }
    }
  }

  .legend {
    @media (max-width: $mobile-L) {
      justify-content: center;
      align-items: center;
    }
  }

}

//.footer {
//  padding-top: 1rem;
//  padding-bottom: 1rem;
//  text-align: center;
//  border-top: 1px solid #eef2f4;
//}
// form calculator end

* {
  box-sizing: border-box;
}

.calc-wrp{
  font-family: inherit;
  margin: 0;
  .container {
    width: 100%;
    max-width: 76.875rem;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.938rem;
    padding-left: 0.938rem;
  }
  .calculator{
    display: flex;
    margin-bottom: 7.5rem;
    padding: 3.75rem;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 80px 4px rgb(0 0 0 / 10%);

    @media (max-width: $tablet) {
      padding: 2.5rem 0.938rem;
    }
    &__fields{
      width: 100%;
      margin-right: 3.75rem;
      flex: 1;
    }
    &__graphics {
      flex: 1;
      width: 100%;
      max-width: 100%;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
    }
    &__prices {
      display: flex;
      margin-bottom: 2.5rem;
      justify-content: space-between;
      grid-column-gap: 1.25rem;
      grid-row-gap: 1.25rem;
    }
    &__price {
      padding: 1.125rem 1.375rem;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      border-radius: 6px;
      background-color: #f5f5f0;
      color: #232323;
    }
    &__price-label {
      text-align: center;
      letter-spacing: -.03125em;
      margin-bottom: 0.625rem;
      font-size: .6875rem;
    }
    &__price-number {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
    }


    .chart{
      &__donut{
        .legend {
          display: flex;
          margin-top: 2.5rem;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          grid-auto-columns: 1fr;
          grid-column-gap: 16px;
          grid-row-gap: 16px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto;
          &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          &__color {
            width: 1.25rem;
            height: 1.25rem;
            margin-bottom: 0.313rem;
            border-radius: 50%;
          }
          &__text {
            color: #828282;
            font-size: 0.875rem;
          }
        }
      }
      &__text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        top: calc(50% - 2.5rem);
        text-align: center;
      }
      &__description {
        margin-bottom: 0.313rem;
        color: #333;
        font-size: 0.875rem;
      }
      &__price {
        color: #ffc842;
        font-size: 1.5rem;
        line-height: 140%;
        font-weight: 600;
        &:before {
          content: "$";
          margin-right: 0.3125em;
          left: 0;
        }
      }

      &__donut-wrp {
        flex: 1 !important;
        width: auto !important;
      }
    }
    @media (max-width: $tablet-L) {
      flex-direction: column;
      &__fields{
        margin-right: 0;
      }
      &__graphics {
        margin-top: 2.5rem;
      }
    }
  }
  .form {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    height: 100%;
    margin: 0;
    &__wrapper {
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      margin: 0;
      display: flex;
    }
    &__layout {
      grid-row-gap: 0;
      margin-bottom: 0;

      display: grid;
      justify-items: stretch;
      align-items: stretch;
      grid-auto-columns: 1fr;
      grid-column-gap: 1.2588rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      @media (max-width: $mobile-L) {
        grid-template-columns: 1fr;
      }
    }
    &__item {
      margin-bottom: 1.875rem;
      &.condition-item {
        margin-bottom: 0;
      }
      .label {
        display: block;
        margin-bottom: 0.625rem;
        color: rgba(92, 92, 92, 0.6);
        font-size: 0.875rem;
        font-weight: 400;
      }
      input{
        display: block;
        width: 100%;
        padding: 8px 12px;
        margin-bottom: 10px;
        line-height: 1.42857143;
        vertical-align: middle;
        height: 3.5rem;
        padding-right: 0.938rem;
        padding-left: 0.938rem;
        border: 0 none #000;
        border-radius: 5px;
        background-color: #f5f5f0;
        font-size: 1rem;
        font-weight: 500;

        color: #000;
        outline: none;
      }

      @media (max-width: $tablet-L) {
        margin-bottom: 0.875rem;
      }
    }

    &__radiobuttons {
      display: flex;
      grid-column-gap: 0.375rem;
      grid-row-gap: 0.375rem;
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
      .radio {
        position: relative;
        display: flex;
        height: 3.5rem;
        justify-content: center;
        align-items: center;
        flex: 1;
        border-radius: 5px;
        background-color: #f5f5f0;
        color: #bdbdbd;
        input[type="radio"] {
          opacity: 0;
          z-index: -1;
          position: absolute;
        }
        input[type="radio"]:checked ~ .radio__icon {
          color: #fff;
          -o-transition: all .2s;
          background-color: #333;
          transition: all .2s;
        }
        &__icon {
          position: absolute;
          left: 0%;
          top: 0%;
          right: 0%;
          bottom: 0%;
          width: 100%;
          height: 3.5rem;
          margin-top: 0px;
          margin-left: 0px;
          border-style: none;
          border-radius: 6px;
          color: #bdbdbd;
          cursor: pointer;
          transition: all .2s;
        }
        &__label {
          display: inline-block;
          cursor: pointer;
          margin-bottom: 0;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    input[type='checkbox'], input[type='radio'] {
      box-sizing: border-box;
      padding: 0;
    }
  }
  &__btn-calc {
    cursor: pointer;
    height: 3.5rem;
    background-color: #ffc842;
    border: none;
    outline: none;
    margin-top: 1.875rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    transition: all .3s;
    position: relative;
    color: #fff !important;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
    outline: none;
    cursor: pointer;

    &:hover{
      background-color: #000;
      color: #fff !important;
    }

    @media (max-width: $tablet) {
      margin-top: 0.875rem;
    }
  }
  .checkbox-inline {
    align-items: center;
    margin-top: 0.375rem;
    margin-bottom: 0;
    padding-left: 1.625rem;
    display: flex;
    position: relative;
    &::before {
      content: ' ';
      display: table;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
    }
    &::after {
      content: ' ';
      display: table;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      -ms-grid-row: 1;
      grid-row-start: 1;
      clear: both;
    }
    input[type="checkbox"] {
      opacity: 0;
      z-index: -1;
      position: absolute;
      left: 0;
    }
    .checkbox__icon {
      width: 1rem;
      height: 1rem;
      opacity: 1;
      z-index: 1;
      cursor: pointer;
      background-position: 50%;
      background-repeat: no-repeat;
      border: 1px solid #bdbdbd;
      border-radius: 0.125rem;
      margin-top: 0;
      margin-right: 0.625rem;
      position: absolute;
      left: 0;
    }
    input[type="checkbox"]:checked ~ .checkbox__icon {
      background-color: #ffc842;
      background-image: url('https://uploads-ssl.webflow.com/6284bc411e7490c177b50355/6284bd274fd8832b710ab303_check.svg');
      background-size: auto;
      border: 1px solid #ffc842;
    }
    .checkbox__label {
      margin-bottom: 0px;
      color: rgba(92, 92, 92, 0.6);
      font-size: 0.813rem;
      line-height: 99%;
      cursor: pointer;
      font-weight: normal;
      display: inline-block;
    }
  }
  .chart__donut {
    height: 100%;
    flex-direction: column;
    display: flex;
    position: relative;
  }
  @media (max-width: $tablet-L) {
    &__inner-line {
      flex-wrap: wrap;
    }
    input {
      width: 100%;
    }
  }
  @media (max-width: $tablet) {
    margin: 0;
  }
}