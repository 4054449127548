.calc-wrp__container {
  width: 90vw;
  max-width: 62.25rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 64rem) {
  .calc-wrp__container {
    width: auto;
    max-width: 62.25rem;
  }
}

.calc-wrp input {
  color: #000;
  outline: none;
}

.calc-wrp input[type="submit"] {
  cursor: pointer;
}

.calc-wrp__btn-calc {
  cursor: pointer;
  height: 3.5rem;
  -o-transition: all 300ms ease;
  background-color: #ffc842;
  border: none;
  outline: none;
  margin-top: 1.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  transition: all .3s;
  position: relative;
  color: #fff !important;
}

@media (max-width: 62rem) {
  .calc-wrp__inner-line {
    flex-wrap: wrap;
  }

  .calc-wrp input {
    width: 100%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp {
    margin: .5rem;
  }
}

.calc-wrp .calculator {
  margin-bottom: 0;
  padding: 3.25rem;
}

.calc-wrp .calculator__price-label {
  letter-spacing: -.03125em;
  margin-bottom: .625rem;
  font-size: .6875rem;
}

@media (max-width: 75rem) {
  .calc-wrp .calculator__fields {
    margin-right: 1.75rem;
  }
}

@media (max-width: 75rem) {
  .calc-wrp .calculator {
    padding: 1.75rem;
  }
}

@media (max-width: 62rem) {
  .calc-wrp .calculator {
    padding: 1.2rem;
  }
}

@media (max-width: 62rem) {
  .calc-wrp .calculator__prices {
    width: 100%;
  }
}

.calc-wrp .calculator__graphics {
  width: 100%;
  max-width: 100%;
}

.calc-wrp .calculator__price-label, .calc-wrp .calculator__price-number {
  text-align: center;
}

.calc-wrp .form {
  height: 100%;
  margin: 0;
}

.calc-wrp .form__item {
  margin-bottom: 1.875rem;
}

.calc-wrp .form__item.hide {
  width: 0;
  height: 0;
  opacity: 0;
  -o-transition: all .2s;
  transition: all .2s;
  display: none;
  overflow: hidden;
}

.calc-wrp .form__layout {
  grid-row-gap: 0;
  margin-bottom: 0;
}

.calc-wrp .form__wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  display: flex;
}

.calc-wrp .form__item.condition-item {
  margin-bottom: 0;
}

@media (max-width: 62rem) {
  .calc-wrp .form__item {
    margin-bottom: .875rem;
  }
}

.calc-wrp .chart__price {
  position: relative;
}

.calc-wrp .chart__price:before {
  content: "$";
  margin-right: .3125em;
  left: 0;
}

.calc-wrp .checkbox-inline {
  align-items: center;
  margin-top: .375rem;
  margin-bottom: 0;
  padding-left: 1.625rem;
  display: flex;
  position: relative;
}

.calc-wrp .checkbox-inline input[type="checkbox"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: 0;
}

.calc-wrp .checkbox-inline input[type="checkbox"]:checked ~ .checkbox__icon {
  background-color: #ffc842;
  background-image: url("https://uploads-ssl.webflow.com/6284bc411e7490c177b50355/6284bd274fd8832b710ab303_check.svg");
  background-size: auto;
  border: 1px solid #ffc842;
}

.calc-wrp .checkbox__label {
  cursor: pointer;
  font-weight: normal;
  display: inline-block;
}

.calc-wrp .checkbox__icon {
  width: 1rem;
  height: 1rem;
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px solid #bdbdbd;
  border-radius: .125rem;
  margin-top: 0;
  margin-right: .625rem;
  position: absolute;
  left: 0;
}

.calc-wrp label.radio input[type="radio"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.calc-wrp label.radio input[type="radio"]:checked ~ .radio__icon {
  color: #fff;
  -o-transition: all .2s;
  background-color: #333;
  transition: all .2s;
}

.calc-wrp .radio__icon {
  -o-transition: all .2s;
  transition: all .2s;
}

.calc-wrp .calculator__graphics {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.calc-wrp .chart__donut {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.calc-wrp .chart__donut-wrp {
  flex: 1;
}

.calc-wrp .chart #donutBox {
  z-index: 3;
  position: relative;
}

.calc-wrp .chart__text {
  z-index: 1;
  top: calc(50% - 2.5rem);
}

@media (max-width: 29.9375rem) {
  .calc-wrp .chart__text {
    width: 8.8125rem;
  }
}

@media (max-width: 29.9375rem) {
  .calc-wrp .chart__price {
    font-size: 1rem;
  }
}

@media (max-width: 29.9375rem) {
  .calc-wrp .legend {
    justify-content: center;
    align-items: center;
  }
}

* {
  box-sizing: border-box;
}

.calc-wrp {
  margin: 0;
  font-family: inherit;
}

.calc-wrp .container {
  width: 100%;
  max-width: 76.875rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: .938rem;
  padding-right: .938rem;
}

.calc-wrp .calculator {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 7.5rem;
  padding: 3.75rem;
  display: flex;
  box-shadow: 1px 1px 80px 4px rgba(0, 0, 0, .1);
}

@media (max-width: 48rem) {
  .calc-wrp .calculator {
    padding: 2.5rem .938rem;
  }
}

.calc-wrp .calculator__fields {
  width: 100%;
  flex: 1;
  margin-right: 3.75rem;
}

.calc-wrp .calculator__graphics {
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.calc-wrp .calculator__prices {
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  display: flex;
}

.calc-wrp .calculator__price {
  color: #232323;
  background-color: #f5f5f0;
  border-radius: 6px;
  flex: 1;
  padding: 1.125rem 1.375rem;
}

.calc-wrp .calculator__price-label {
  text-align: center;
  letter-spacing: -.03125em;
  margin-bottom: .625rem;
  font-size: .6875rem;
}

.calc-wrp .calculator__price-number {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
}

.calc-wrp .calculator .chart__donut .legend {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  flex-direction: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  display: flex;
}

.calc-wrp .calculator .chart__donut .legend__item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.calc-wrp .calculator .chart__donut .legend__color {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin-bottom: .313rem;
}

.calc-wrp .calculator .chart__donut .legend__text {
  color: #828282;
  font-size: .875rem;
}

.calc-wrp .calculator .chart__text {
  z-index: 1;
  text-align: center;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: 50%;
  transform: translate(-50%, -50%);
}

.calc-wrp .calculator .chart__description {
  color: #333;
  margin-bottom: .313rem;
  font-size: .875rem;
}

.calc-wrp .calculator .chart__price {
  color: #ffc842;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 140%;
}

.calc-wrp .calculator .chart__price:before {
  content: "$";
  margin-right: .3125em;
  left: 0;
}

.calc-wrp .calculator .chart__donut-wrp {
  width: auto !important;
  flex: 1 !important;
}

@media (max-width: 62rem) {
  .calc-wrp .calculator {
    flex-direction: column;
  }

  .calc-wrp .calculator__fields {
    margin-right: 0;
  }

  .calc-wrp .calculator__graphics {
    margin-top: 2.5rem;
  }
}

.calc-wrp .form {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  height: 100%;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin: 0;
}

.calc-wrp .form__wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  display: flex;
}

.calc-wrp .form__layout {
  grid-row-gap: 0;
  display: -ms-grid;
  grid-column-gap: 1.2588rem;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  justify-items: stretch;
  margin-bottom: 0;
  display: grid;
}

@media (max-width: 29.9375rem) {
  .calc-wrp .form__layout {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.calc-wrp .form__item {
  margin-bottom: 1.875rem;
}

.calc-wrp .form__item.condition-item {
  margin-bottom: 0;
}

.calc-wrp .form__item .label {
  color: rgba(92, 92, 92, .6);
  margin-bottom: .625rem;
  font-size: .875rem;
  font-weight: 400;
  display: block;
}

.calc-wrp .form__item input {
  width: 100%;
  vertical-align: middle;
  height: 3.5rem;
  color: #000;
  background-color: #f5f5f0;
  border: 0 #000;
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
  padding: 8px .938rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.42857;
  display: block;
}

@media (max-width: 62rem) {
  .calc-wrp .form__item {
    margin-bottom: .875rem;
  }
}

.calc-wrp .form__radiobuttons {
  grid-column-gap: .375rem;
  grid-row-gap: .375rem;
  display: flex;
}

.calc-wrp .form__radiobuttons label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.calc-wrp .form__radiobuttons .radio {
  height: 3.5rem;
  color: #bdbdbd;
  background-color: #f5f5f0;
  border-radius: 5px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.calc-wrp .form__radiobuttons .radio input[type="radio"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.calc-wrp .form__radiobuttons .radio input[type="radio"]:checked ~ .radio__icon {
  color: #fff;
  -o-transition: all .2s;
  background-color: #333;
  transition: all .2s;
}

.calc-wrp .form__radiobuttons .radio__icon {
  width: 100%;
  height: 3.5rem;
  color: #bdbdbd;
  cursor: pointer;
  -o-transition: all .2s;
  border-style: none;
  border-radius: 6px;
  margin-top: 0;
  margin-left: 0;
  transition: all .2s;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.calc-wrp .form__radiobuttons .radio__label {
  cursor: pointer;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calc-wrp .form input[type="checkbox"], .calc-wrp .form input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.calc-wrp__btn-calc {
  cursor: pointer;
  height: 3.5rem;
  -o-transition: all .3s;
  white-space: nowrap;
  white-space: nowrap;
  cursor: pointer;
  background-color: #ffc842;
  border: none;
  border-radius: 5px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin-top: 1.875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  position: relative;
  color: #fff !important;
}

.calc-wrp__btn-calc:hover {
  background-color: #000;
  color: #fff !important;
}

@media (max-width: 48rem) {
  .calc-wrp__btn-calc {
    margin-top: .875rem;
  }
}

.calc-wrp .checkbox-inline {
  align-items: center;
  margin-top: .375rem;
  margin-bottom: 0;
  padding-left: 1.625rem;
  display: flex;
  position: relative;
}

.calc-wrp .checkbox-inline:before {
  content: " ";
  -ms-grid-column-span: 1;
  -ms-grid-column: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row: 1;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.calc-wrp .checkbox-inline:after {
  content: " ";
  -ms-grid-column-span: 1;
  -ms-grid-column: 1;
  -ms-grid-row-span: 1;
  -ms-grid-row: 1;
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.calc-wrp .checkbox-inline input[type="checkbox"] {
  opacity: 0;
  z-index: -1;
  position: absolute;
  left: 0;
}

.calc-wrp .checkbox-inline .checkbox__icon {
  width: 1rem;
  height: 1rem;
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 1px solid #bdbdbd;
  border-radius: .125rem;
  margin-top: 0;
  margin-right: .625rem;
  position: absolute;
  left: 0;
}

.calc-wrp .checkbox-inline input[type="checkbox"]:checked ~ .checkbox__icon {
  background-color: #ffc842;
  background-image: url("https://uploads-ssl.webflow.com/6284bc411e7490c177b50355/6284bd274fd8832b710ab303_check.svg");
  background-size: auto;
  border: 1px solid #ffc842;
}

.calc-wrp .checkbox-inline .checkbox__label {
  color: rgba(92, 92, 92, .6);
  cursor: pointer;
  margin-bottom: 0;
  font-size: .813rem;
  font-weight: normal;
  line-height: 99%;
  display: inline-block;
}

.calc-wrp .chart__donut {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

@media (max-width: 62rem) {
  .calc-wrp__inner-line {
    flex-wrap: wrap;
  }

  .calc-wrp input {
    width: 100%;
  }
}

@media (max-width: 48rem) {
  .calc-wrp {
    margin: 0;
  }
}

/*# sourceMappingURL=index.css.map */
